@use "sass:math";
@use "global" as *;
// section title

.section-title {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: length(32);
  margin: length(120) 0 length(72);
  @include mq {
    gap: length-md(32);
    margin: length-md(120) 0 length-md(72);
  }
}
.section-title__treat {
  &.is-visible{
    .title-treat {

      transform: rotate(0deg);
    }

  }
}
.title-treat {
  display: block;
  width: length(24);
  transition: transform ease-in-out 1.2s;
  transform: rotate(-90deg);
  @include mq {
    width: length-md(21);
  }
}
.section-title__text {
  @include font-size(48);
  @include mq {
    @include font-size-md(40);
  }
}
// .section-title__treat {
// }
