@use "sass:math";
@use "global" as *;

.footer {
  position: relative;
  background: #fff;
  @include overInnerWide;
  @include font-size-md(10);
  padding: length(32) length(40);
  @include mq {
    padding: length-md(58) length-md(96);
  }
}

.footer-grid {
  border-top: 1px solid rgba(#707070, 0.2);
  @include mq {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.footer-sitelock{
  display: flex;
  max-width: 117px;
  margin:0 auto length(40);
  @include mq {
    margin:auto;
  }
}

.footer-logo {
  width: length(380);
  margin: auto;
  @include mq {
    margin: 0;
    width: length-md(270);
  }
}
.footer-grid__links {
  text-align: center;
  padding: length(40) 0;
  @include mq {
    padding: length-md(40) 0;
    display: grid;
    align-items: center;
    gap: length-md(32);
  }
}

.footer-outside {
  margin-top: length(28);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: length(24) 0;
  @include mq {
    justify-content: flex-start;
    margin-top: 0;
    gap: length-md(10) 0;
  }
}
.footer-outside__item {
  &:not(:last-child) {
    padding-right: 1.5em;
    margin-right: 1.5em;
    position: relative;
    // @include font-size(18);
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 100%;
      background: map-get($map: $colors, $key: "black");
    }
  }
}
.footer-copy {
  text-align: center;
  // margin-top: length(28);
  @include font-size(16);
  @include mq {
    @include font-size-md(10);
    // margin-top: length-md(20);
    // text-align: initial;
  }
}
