@use "sass:math";
@use "global" as *;

.anim-fadeup {
  // transition-timing-function: ease-in-out;
  // transition-property: transform, opacity;
  // transition-duration: 0.6s;
  // will-change: opacity, transform;
  transition: opacity ease-in-out 0.6s, transform ease-in-out 0.6s;
  opacity: 0;
  transform: translate(0, length(20));
  // visibility: hidden;
  @include mq {
    transform: translate(0, length-md(20));
  }
  &.is-visible {
    opacity: 1;
    // visibility: visible;
    transform: translate(0, 0);
  }
}

.anim-fade {
  will-change: opacity;
  transition: opacity ease-in-out 0.6s;
  opacity: 0;
  // visibility: hidden;
  // transform: translate(0, 20px);

  &.is-visible {
    opacity: 1;
    // visibility: visible;
    // transform: translate(0, 0);
  }
}
.anim-clip {
  transition: clip-path ease-in-out 0.6s;

  transition-duration: 1s;
  clip-path: circle(0% at center center);
  &.is-visible {
    clip-path: circle(100% at center center);
  }
}
