@use "sass:math";
@use "global" as *;

.index-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: length(400);
  height: length(112);
  border-radius: length(56);
  transition: opacity ease-in-out 0.36s;
  @include mq {
    width: length-md(320);
    height: length-md(72);
    border-radius: length-md(36);
  }
  @include hover {
    opacity: 0.5;
  }
}
.index-button--blue {
  background: map-get($map: $colors, $key: "pastelBlue");
}
.index-button--green {
  background: map-get($map: $colors, $key: "lightGreen");
}
.index-button__text {
  text-transform: uppercase;
  color: map-get($map: $colors, $key: "white");
  @include font-size-md(24);
}
