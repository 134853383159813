// .bg--contain {
//   .bg__img {
//     object-fit: contain;
//   }
// }
// .bg--left-center {
//   .bg__img {
//     object-position: left center;
//   }
// }
// .bg--left-center-md {
//   .bg__img {
//     @include mq {
//       object-position: left center;
//     }
//   }
// }
.bg__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -100;

  object-fit: cover;
}
