@use "sass:math";
@use "global" as *;
.layer-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: length(520);
  height: length(112);
  border-radius: length(56);
  overflow: hidden;
  margin: auto;
  transition: opacity ease-in-out 0.36s;

  background: url(../img/common/bg_layer_button.png) center center / 500%
    no-repeat;
  @include mq {
    width: length-md(520);
    height: length-md(88);
    border-radius: length-md(44);
  }
  @include hover {
    opacity: 0.5;
  }
}
.layer-button__text {
  @include font-size(32);
  color: map-get($map: $colors, $key: "deepBlue");
  text-transform: uppercase;
  @include mq {
    @include font-size-md(24);
  }
}
