@use "global" as *;

.scroll-locked {
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.br {
  &::before {
    content: "\A";
    white-space: pre;
  }
  &--sp {
    @include mq {
      &::before {
        content: "";
        white-space: normal;
      }
    }
  }
  &--md {
    &::before {
      content: "";
      white-space: normal;

      @include mq {
        content: "\A";
        white-space: pre;
      }
    }
  }
}

.inline-block {
  display: inline-block;
}
.none-sp {
  display: none;

  @include mq {
    display: block;
  }
}
.none-md {
  @include mq {
    display: none;
  }
}
