@use "sass:math";
@use "global" as *;

.text--small {
  $line-height: 2;
  $line-height-md: 2.2;

  @include line-height-crop($line-height);
  @include font-size(22);

  line-height: $line-height;
  // https://deep-space.blue/web/2240
  letter-spacing: 0.06em;

  @include mq {
    @include font-size-md(14);
    @include line-height-crop($line-height-md, false);
  }
}
.text {
  $line-height: 2;
  $line-height-md: 2.5;

  @include font-size(26);

  line-height: $line-height;
  // https://deep-space.blue/web/2240
  letter-spacing: 0.06em;

  @include mq {
    @include font-size-md(16);
  }
}
.text-large {
  $line-height: 2;
  $line-height-md: 1.5;

  @include font-size(26);

  line-height: $line-height;
  // https://deep-space.blue/web/2240
  letter-spacing: 0.06em;

  @include mq {
    @include font-size-md(24);
  }
}
.text--bold {
  @include default-font-bold;
}
.text--extra-large {
  $line-height: 2;
  $line-height-md: 1.5;

  @include font-size(26);

  line-height: $line-height;
  // https://deep-space.blue/web/2240
  letter-spacing: 0.06em;

  @include mq {
    @include font-size-md(40);
  }
}
.txt--bold {
  @include default-font-bold;
}
