@use "sass:math";
@use "global" as *;

.section-connect {
  @include overInnerWide;
  aspect-ratio: 992/390;
  width: calc(var(--vw, 1vw) * 100);
  background: url(../img/common/img_section_connect.png) center
    center/contain no-repeat;
  @include mq {
    width: 100%;
    margin: 0;
  }
}
