@use "sass:math";
@use "global" as *;

// - - - - - - - - - - - - - - - - - - - - -
//
// kv
//
// - - - - - - - - - - - - - - - - - - - - -
.layer-kv-wrap{

  @include overInnerWide;
  margin-left:calc(50% - 50vw) ;
  margin-right:calc(50% - 50vw) ;
}
.layer-kv {
  display: grid;
  position: relative;
  margin-bottom: length-md(175);
  &:before {
    transition: opacity ease-in-out 1.6s, transform ease-in-out 1.2s;
    opacity: 0;
    visibility: hidden;
    transform: translate(length(20), length(20));
    content: "";
    aspect-ratio: 1280/252;
    width: 100%;
    position: absolute;
    background: url(../img/common/img_layer_kv_below.png) center
      center / contain no-repeat;
    z-index: 1;
    bottom: -5%;
    @include mq {
      bottom: -10%;
      transform: translate(calc(length-md(80) * -1), length-md(20));
    }
  }
  &.is-visible {
    &:before {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
    .layer-kv__image {
      opacity: 1;
      visibility: visible;
      filter: brightness(1);
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.layer-kv__text,
.layer-kv__image {
  grid-column: 1/2;
  grid-row: 1/2;
}
.layer-kv__text {
  position: relative;
  z-index: 1;
  color: map-get($map: $colors, $key: "white");
  display: flex;
  align-items: center;
  justify-content: center;
}
.kv-text {
  text-align: center;
}
.kv-text__en {
  @include font-size(40);
  width: fit-content;
  padding: 0 length(32) length(32);
  border-bottom: 2px solid;
  margin: 0 auto length(32);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transition-delay: 1s;
  @include mq {
    margin: 0 auto length-md(32);
    padding: 0 length-md(32) length-md(32);
    @include font-size-md(24);
  }
}
.kv-text__ja {
  @include default-font-bold;
  @include font-size(56);
  white-space: pre-line;
  letter-spacing: 0.08em;
  &.anim-fade-text {
    transition-delay: 4s;
  }

  @include mq {
    @include font-size-md(40);
  }
}

.layer-kv__image {
  transition: filter ease-in-out 1s, opacity ease-in-out 1s;
  position: relative;
  mask-image: url(../img/common/img_layer_mask_sp.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: bottom;
  filter: brightness(1.15);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  @include mq {
    mask-image: url(../img/common/img_layer_mask.png);
  }
  &:before {
    transition: opacity ease-in-out 1s;
    transition-delay: 0.8s;
    opacity: 0;
    visibility: hidden;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: rgba(map-get($map: $colors, $key: "black"), 0.3);
  }
}
