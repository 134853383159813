@use "sass:math";
@use "global" as *;
// サイドマージン
$side-width: 64;
$side-width-md: 228;
// body {
//   &.is-fixed {
//     overflow: hidden;
//   }
// }
.header {
  position: fixed;
  z-index: 999;
  width: calc((100% - (length(40) * 2)));
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: length(36) length(32);
  @include mq {
    // box-shadow: 0 length-md(2) length-md(30) rgba(0, 0, 0, 0.16);
    padding: length-md(26) length-md(50);
    width: calc((100% - (length-md(50) * 2)));
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: map-get($map: $colors, $key: "white");
    border-radius: 0 0 length(16) length(16);
    box-shadow: 0 length(2) length(30) rgba(0, 0, 0, 0.16);
    @include mq {
      border-radius: 0 0 length-md(16) length-md(16);
      box-shadow: 0 length-md(2) length-md(30) rgba(0, 0, 0, 0.16);
    }
  }
  &.is-active {
    &:after {
      box-shadow: none;
    }
  }
}
.header-logo {
  display: block;
  width: length(380);
  position: relative;
  z-index: 10;
  @include mq {
    width: length-md(270);
  }
}
.header-logo__image {
}
.header-nav {
  transition: all ease-in-out 0.36s;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  z-index: -2;
  background: map-get($map: $colors, $key: "white");
  border-radius: 0 0 length(16) length(16);
  display: flex;
  flex-direction: column;
  gap: length(60);
  padding: length(120) length(0) length(52);
  transform: translateY(-100%);
  @include mq {
    transform: unset;
    padding: 0;
    flex-direction: unset;
    position: relative;
    gap: length-md(20);
    opacity: 1;
    visibility: visible;
    z-index: 1;
    width: auto;
    height: auto;
  }
  &.is-active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    box-shadow: 0 length(2) length(30) rgba(0, 0, 0, 0.16);
  }
}
.header-nav__item {
  text-align: center;
  position: relative;
  &:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(length(32) * -1);
      height: 2px;
      width: length(80);
      background: rgba(map-get($map: $colors, $key: "black"), 0.2);
      z-index: 1;
      @include mq {
        content: none;
      }
    }
  }
  &:after {
    position: absolute;
    transition: all ease-in-out 0.36s;
    bottom: calc(length-md(5) * -1);
    left: 0;
    height: 2px;
    width: 0%;
    background: map-get($map: $colors, $key: "green");
    @include mq {
      content: "";
    }
  }
  @include hover {
    &:after {
      width: 100%;
    }
  }
}
.nav-text {
  position: relative;
  text-transform: uppercase;
  @include default-font-bold;
  @include font-size(28);
  // height: length-md(20);
  overflow: hidden;
  // &:after {
  //   color: map-get($map: $colors, $key: "green");
  //   content: attr(data-text) "";
  //   display: block;
  //   @include default-font-bold;
  //   @include font-size(32);
  //   @include mq {
  //     @include font-size-md(14);
  //   }
  // }
  @include mq {
    @include font-size-md(14);
  }
}
.header-button {
  display: block;
  aspect-ratio: 56/37;
  width: length(56);
  position: relative;
  z-index: 10;
  @include mq {
    display: none;
  }
  &.is-active {
    .header-button__line {
      &:first-child {
        transform: rotate(-36deg);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: rotateY(90deg);
      }
      &:last-child {
        transform: rotate(36deg);
      }
    }
  }
}
.header-button__line {
  position: absolute;

  width: 100%;
  height: length(3);
  background: map-get($map: $colors, $key: "green");
  transition: transform ease-in-out 0.36s, opacity ease-in-out 0.36s;
  transform-origin: right;
  &:first-child {
    top: 0;
  }
  &:nth-child(2) {
    top: 50%;
  }
  &:last-child {
    bottom: 0;
  }
}
