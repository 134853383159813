@use "sass:math";
@use "global" as *;

.container {
  display: flex;
  justify-content: center;
  padding: 0 vmin($side-width);

  @include mq {
    padding: 0 vw($side-width-md, $desktop);
  }
}
.container__inner {
  width: 100%;

  @include mq {
    max-width: ($desktop - $side-width-md * 2) * 1.25;
  }
}
.container__footer {
  position: relative;
  padding-top: length(192);
  z-index: 0;

  @include mq {
    padding-top: length-md(150);
  }
}
